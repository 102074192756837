<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="searchbar-content">
           <div class="title"><span>IP</span></div>
           <div>
              <input type="text" class="mr-5" v-model="reqData.ip" :placeholder="$t('common.moveIp')" />
              <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
              </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head-sorting :headInfo="headInfo" @sort="onSort" :orderColumn="reqData.orderColumn" :orderType="reqData.orderType" />
          <tbody>
            <template v-for="(item, idx) in ipList" :key="idx">
              <tr>
                <td>
                  <button type="button" class="btn-link roboto" @click="detailView(item)">
                    {{ item.ip }}
                  </button>
                </td>
                <td>{{ item.memList.length }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="ipList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modalWrap" v-if="modal.active">
      <div class="modal-item ">
        <article class="modal-title">
          <div>
            <span>IP :</span>&nbsp;
            <span>{{ modal.target }}</span>
          </div>
          <button type="button" class="btn-link" @click="detailView">
            <i class="fas fa-times"></i>
          </button>
        </article>
        <div class="max500">
           <table class="mainTable modal-table">
             <table-head :headInfo="subHeadInfo" />
             <tbody>
               <tr v-for="(item, idx) in modal.list" :key="idx">
                 <td>
                   {{idx + 1}}
                 </td>
                 <td>
                   <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                 </td>
                 <td>
                  <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
                 </td>
                 <td :class="item.partnerLevel">
                   <span class="box">{{computedPartnerLevel(item)}}</span>
                 </td>
                 <td>
                    <div class="topwrap" v-if="item.topUserList.length > 0">
                      <select class="select">
                        <option class="option" v-for="pt in item.topUserList" :key="pt.recommenderId">
                          <span class="topbox">[{{computedPartnerLevel(pt)}}] </span>
                          <span>{{pt.recommenderId}}</span>
                        </option>
                      </select>
                      <i class="icon">+</i>
                    </div>
                 </td>
                 <td class="actionbtn">
                   <div class="action">
                     <template v-if="item.memColor === 'blue'">
                       <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bl">{{ $t('table.head.userState') }}</a>
                     </template>
                     <template v-if="item.memColor === 'black'">
                       <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-bk">{{ $t('table.head.userState') }}</a>
                     </template>
                     <template v-if="item.memColor === 'red'">
                       <a @click="openMemberPopup(item, 'isOpenStatus')" class="btn-inout bg-rd">{{ $t('table.head.userState') }}</a>
                     </template>
                   </div>
                 </td>
                 <td>
                   {{thousand(item.loginCnt)}}
                 </td>
                 <td>
                   <div class="memo-btn">
                     <a @click="item.isOpenMemo = !item.isOpenMemo"><img src="@/assets/img/icon_memos.svg" /></a>
                     <div class="memo-box-wrap" v-if="item.isOpenMemo">
                       <div class="memo-box-in">
                         <div class="memo-box-title">Memo</div>
                         <div class="memo-box-content">
                           <textarea :placeholder="$t('txt.enterNote')" v-model="item.memo"></textarea>
                         </div>
                       </div>
                       <div class="memo-box-btn">
                         <a @click="setMemo(item)">{{ $t('searchArea.modify') }}</a>
                         <a @click="item.isOpenMemo = false;">{{ $t('searchArea.cancel') }}</a>
                       </div>
                     </div>
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
        </div>
      </div>
    </div>

    <div class="modal-wrapper" v-if="isOpenStatus">
      <div class="modal-wrap2">
        <div class="sub-title2">
          <h2>{{ selectMember.memName }} {{ $t('txt.statusNow') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenStatus = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li>
              <span>{{ $t('common.id') }} :</span>
              <span>{{ selectMember.memId }}</span>
            </li>
            <li>
              <span>{{ $t('common.nickName') }} :</span>
              <span class="fc-org">{{ selectMember.memNick }}</span>
            </li>
          </ul>
          <ul class="modal-set">
            <li>
              <em>{{ $t('common.setBlack') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isMemBlack"/>
            </li>
            <li>
              <em class="fc-rd">{{ $t('common.blockSet') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isMemLock"/>
            </li>
            <li>
              <em>{{ $t('user.isLogin') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isLogin"/>
            </li>
            <li>
              <em>{{ $t('table.head.betYn') }}</em>
              <input type="checkbox" class="set-switch" v-model="selectMember.isBet"/>
            </li>
          </ul>
          <p class="modal-txt">{{ $t('txt.changeStat') }}</p>
          <div class="modal-btns">
            <a @click="setStatus(selectMember)">{{ $t('common.save') }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableHeadSorting from '@/components/main/table/HeadSorting.vue'
import TableHead from '@/components/main/table/Head.vue'
import Pagination from '@/components/common/Pagination'
import { multipleDetail, multipleUsers, setMemberMemo } from '@/api/member.js'
import { thousand } from '../../../../libs/utils'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'UserIpList',
  components: {
    TableHeadSorting,
    TableHead,
    Pagination,
    Memo
  },
  data () {
    return {
      selectMember: null,
      isOpenStatus: false,
      isOpenMemo: false,
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: [
          { key: 'userIp' },
          { key: 'userIpId', sort: true, sortKey: 'CNT' }
        ]
      },
      subHeadInfo: {
        fstColumn: false,
        dataList: ['index', 'memId', 'nick', 'level', 'upper', 'userState', 'connectNum', 'memo']
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        memId: '',
        ip: '',
        orderStr: 'CNT_DESC'
      },
      srchFiltersProp: {
        placeholder: '동작 아이피',
        selectOptions: ''
      },
      ipList: [],
      pageInfo: {
        page: 0,
        count_per_list: 0,
        tatal_list_count: 0
      },
      modal: {
        active: false,
        target: '',
        list: []
      }
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    thousand,
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
    },
    getDomain () {
      return location.host
    },
    pageSeach () {
      this.setTableData(1)
    },
    onSort (key, type) {
      if (this.reqData.orderStr === 'CNT_DESC') {
        this.reqData.orderStr = 'CNT_ASC'
      } else {
        this.reqData.orderStr = 'CNT_DESC'
      }

      console.log(key, type)
      this.setTableData()
    },
    detailView (obj) {
      console.log(obj)
      if (!this.modal.active) {
        this.modal.target = obj.ip

        const params = {
          ip: obj.ip
        }
        multipleDetail(params).then(res => {
          if (res.resultCode === '0') {
            this.modal.list = res.data.list
            this.modal.list.forEach(item => {
              if (item.lastLoginDt) {
                const _date = new Date(item.lastLoginDt)
                item.lastLoginDt = this.getDateStr(_date, 'yy-MM-dd HH:mm:ss')
              }

              item.isMemLock = item.memLockYn === 'Y'
              item.isMemBlack = item.memBlackYn === 'Y'
              item.isLogin = item.loginYn === 'Y'
              item.isBet = item.betYn === 'Y'

              item.memColor = 'blue'

              if (item.memLockYn === 'Y') {
                item.memColor = 'red'
              } else {
                if (item.memBlackYn === 'Y') {
                  item.memColor = 'black'
                }
              }
            })
            this.modal.active = !this.modal.active
          }
        })
      } else {
        this.modal.target = ''
        this.modal.list = []
        this.modal.active = !this.modal.active
      }
    },
    async setMemo (item) {
      if (!item.memo) {
        alert('내용을 입력해주세요.')
      } else {
        const req = {
          memId: item.memId,
          memo: item.memo
        }
        const res = await setMemberMemo(req)
        if (res.resultCode === '0') {
          alert('메모 입력 완료')
        } else {
          alert('메모입력 실패, 다시 시도해주세요.')
        }
        item.isOpenMemo = false
      }
    },
    async setStatus (item) {
      const req = {
        memId: item.memId,
        memBlackYn: item.isMemBlack === true ? 'Y' : 'N',
        memLockYn: item.isMemLock === true ? 'Y' : 'N',
        loginYn: item.isLogin === true ? 'Y' : 'N',
        betYn: item.isBet === true ? 'Y' : 'N'
      }
      const res = await setMemberMemo(req)
      if (res.resultCode === '0') {
        item.memBlackYn = req.memBlackYn
        item.memLockYn = req.memLockYn

        item.memColor = 'blue'
        if (item.isMemBlack === true) {
          item.memColor = 'black'
        }
        if (item.isMemLock === true) {
          item.memColor = 'red'
        }
        alert('상태 수정 완료')
      } else {
        alert('상태 수정  실패, 다시 시도해주세요.')
      }
      this.isOpenStatus = false
    },
    async setTableData (pageNum) {
      this.reqData.page = pageNum
      const req = { ...this.reqData }
      console.log(req)
      const res = await multipleUsers(req)
      const list = res.data.list
      if (list) {
        for (const item of list) {
          item.memList = item.memList.split(',')
        }
        this.ipList = list
      }
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo.page = pageInfo.page
        this.pageInfo.count_per_list = pageInfo.count_per_list
        this.pageInfo.tatal_page_count = Math.ceil(pageInfo.tatal_list_count / pageInfo.count_per_list)
      }
    }
  },
  async created () {
    this.setTableData(1)
  }
}
</script>

<style scoped>
.memo-btn, .action {display: flex;align-items: center;justify-content: center;position: relative;}
.memo-box-wrap {left: -18px;bottom: 30px;}
.active .btnPhoneNum {
  display: none;
}
.max500 {max-height: 500px;overflow-y: auto;}
.modal-item {
  width: 40%;
  margin: 0 auto;
  position: relative;
  top: 20%;
  background: #fafafa;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c1c1c1;
  padding: 15px 25px;
}
.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  padding-bottom: 10px;
  border-bottom: solid 2px #959595;
  color: #646464;
  font-size: 13px;
  box-sizing: border-box;
}
.modal-title i {
  font-size: 1.5em;
}
.modal-table {
  margin-top: 20px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d5d5d5;
}
.modal-table td,
.modal-table th {
  border-bottom: solid 1px #ddd;
  height: 42px;
}
.table-wrapper {max-width: 570px;}
.searchwrap {max-width: 580px;}
</style>
